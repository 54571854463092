import Modal from "react-modal";
import React from "react";
import Logo from "../components/Logo/Logo";
import { IItem } from "../constants/coffees";
import AddItemForm from "../components/AddItemForm/AddItemForm";
import RemoveItemButton from "../components/RemoveItemButton/RemoveItemButton";
import fetchProducts from "../helpers/fetchItems";

const AdminPage = () => {
  const [items, setItems] = React.useState<IItem[]>([]);
  const [isFormModalOpen, setFormModalOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false); // Loading state for when submitting an order

  React.useEffect(() => {
    fetchProducts(setItems);
  }, []);



    // mock api pre to ked testujem jak to vyzera na mobile

    // const fetchProducts = async () => {
    //     try {
    //       const url = '/items.json';  // Local path to the JSON file
    //       const res = await fetch(url);
    //       const data: IItem[] = await res.json();
    
    //       setItems(
    //         data.map((item: IItem) => {
    //           return { ...item, count: 1 };
    //         })
    //       );
    //     } catch (error) {
    //       console.error('Error fetching items:', error);
    //     }
    //   };
    
    //   React.useEffect(() => {
    //     fetchProducts();
    //   }, []);


      
  function handleRemove(id: number): void {
    setItems(items.filter(item => item.id !== id));
  }
  const closeAddItemModal = () => {
    setFormModalOpen(false);
  };


  // pouzite na nahodne farby pre itemy :)
  const hashString = (str:string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };
  const intToRGB = (hash:number) => {
    const r = (hash & 0xFF0000) >> 16;
    const g = (hash & 0x00FF00) >> 8;
    const b = (hash & 0x0000FF) -100;
    return `rgba(${r}, ${g}, ${b},0.2)`;
  };


    return (
        <>
            
        <div className="h-screen max-h-screen bg-gray-800">
            <div className="pb-10 ">
                <Logo name="Správa UBaRe"/>    
            </div>
          
              <div className="flex justify-center">
                <button className="bg-lightblue text-lightyellow rounded-md py-2 px-5 mb-3 text-md font-bold active:scale-105 active:drop-shadow-xl ${loading ? " onClick={() => setFormModalOpen(true)}>Pridať item</button>
              </div>

              <div className="max-h-[80%] flex justify-around">
              
              <Modal
              onRequestClose={closeAddItemModal}
              isOpen={isFormModalOpen}
              style={{
                overlay: {
                  // position: "fixed",
                  // zIndex: 1020,
                  // top: 0,
                  // left: 0,
                  // width: "100vw",
                  // height: "100vh",
                  background: "rgba(55, 55, 55, 0.8)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                content: {
                  // background: "white",
                  // width: "50rem",                  
                  // overflowY: "auto",
                  position: "relative",
                  border: "1px solid #ccc",
                  borderRadius: "0.3rem",
                },
              }}
>
              <AddItemForm setItems={setItems} setFormModalOpen={setFormModalOpen}/>
              </Modal>
    <table className="text-white text-xl justify-center min-w-[50%]">
      <thead>
        <tr className="text-center ">
          <th >Image</th>
          <th >Name</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          const hash = hashString(item.name);
          const backgroundColor = intToRGB(hash);
          return(
          <tr key={index} style={{backgroundColor: `${backgroundColor}`}}>
            <td className=" flex justify-center items-center">
              <img width={100} height={100} src={item.image_path} alt={item.name}  />
            </td>
            <td className="text-center">{item.name}</td>
            <td className="text-center">{item.price}</td>
            <td className="text-center">
                <RemoveItemButton item_id={item.id} onRemove={handleRemove}/>
              </td>
          </tr>
        );
      })}
      </tbody>
    </table>
            </div>
          </div>
         
        </>
    );
};
export default AdminPage;