import React from "react";
import { IItem } from "../../constants/coffees";
import cx from "classnames";
import menu_button_dots2 from "../../images/menu_button_dots2.png";

interface IProps extends IItem {
  onClick: () => void;
  onExtendedModalOpen: () => void;
}

const Tile = ({
  name,
  image_path,
  className,
  onClick,
  onExtendedModalOpen,
}: IProps) => {
  // const [effect, setEffect] = React.useState(false);

  return (
    <div className={cx("relative hover:-translate-y-1 active:scale-95", {})}>
      <div
        onClick={onExtendedModalOpen}
        className="absolute top-0 right-0 rounded-tr-xl rounded-bl-[50px] pr-12 pt-12 text-2xl text-white hover:scale-110 active:scale-125"
      >
        <img
          width={7}
          height={30}
          src={menu_button_dots2}
          alt="menu"
          className="absolute top-3 right-4"
        />
      </div>
      <div
        onClick={() => {
          // setEffect(true);
          onClick();
        }}
        // onAnimationEnd={() => setEffect(false)}
        className={cx(
          "w-56 h-56 bg-lightblue flex flex-col items-center justify-center gap-y-2 rounded-xl active:bg-slate-800"
          // {
          //   "bg-slate-800 animate-zoom scale-90": effect,
          // }
        )}
      >
        <img width={160} height={160} src={image_path} className={className} />
        <p className="text-lightyellow font-semibold text-lg">{name}</p>
      </div>
    </div>
  );
};

export default Tile;
