import { IItem } from '../constants/coffees';

const fetchItems = async (setItems: React.Dispatch<React.SetStateAction<IItem[]>>) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/items`;
    const res = await fetch(url);
    const data = await res.json();

    setItems(
      data.map((item: IItem) => {
        return { ...item, count: 1 };
      })
    );
  } catch (error) {
    console.error('Error fetching items:', error);
  }
};

export default fetchItems;
