import React, { useState, useRef } from 'react';
import { IItem } from '../../constants/coffees';
import fetchItems from '../../helpers/fetchItems';
import loadingGIF from '../../images/loadingGIF.gif';

interface IProps {
  setItems: (React.Dispatch<React.SetStateAction<IItem[]>>);
  setFormModalOpen: (React.Dispatch<React.SetStateAction<boolean>>);
}

const AddItemForm = ({ setItems, setFormModalOpen }: IProps) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState<number>(0);
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (name && price && image) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/items`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            price,
            screen: 'upipe',
            options: [],
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const newItem = await response.json() as IItem;

        const formData = new FormData();
        formData.append('image', image);

        const secondResponse = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/items/${newItem.id}/set-image`, {
          method: 'POST',
          body: formData,
        });

        if (!secondResponse.ok) {
          throw new Error('Second network response was not ok');
        }

        fetchItems(setItems);

        setName('');
        setPrice(0);
        setImage(null);
        setPreview(null);

        setLoading(false);
        setFormModalOpen(false);

      } catch (error) {
        console.error('Error adding item:', error);
      }
    } else {
      console.log("Please fill out all fields");
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg flex">
        <div className="mr-6">
          <div
            className="border border-gray-300 rounded-lg cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
          >
            {preview ? (
              <img src={preview} alt="Preview" className="w-32 h-32 object-cover rounded-lg" />
            ) : (
              <div className="w-32 h-32 flex items-center justify-center text-gray-400">
                Click to select image
              </div>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="hidden"
          />
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Add New Item</h2>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              Price:
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(parseFloat(e.target.value))}
                required
                className="block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500"
              />
            </label>
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg flex items-center justify-center">
            {loading ? <img src={loadingGIF} alt="Loading..." className="h-5 w-5"/> :  "Pridať item"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddItemForm;