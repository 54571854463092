import React from "react";

interface IProps {
  title: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
}

const CheckBox = ({ title, onChange, checked }: IProps) => {
  return (
    <div>
      <div className="flex items-center pt-3">
        <input
          checked={checked}
          onChange={(ev) => onChange(ev.target.checked)}
          id={title}
          type="checkbox"
          className="w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          htmlFor={title}
          className="ml-2 text-md font-medium text-gray-900"
        >
          {title}
        </label>
      </div>
    </div>
  );
};

export default CheckBox;
