import "./App.css";
import MakeOrderPage from "./pages/MakeOrderPage";
import { Routes, Route } from "react-router-dom";
import ActiveOrdersPage from "./pages/ActiveOrdersPage";
import AdminPage from "./pages/AdminPage";
import { IItem } from "./constants/coffees";

export interface IOrder {
  id?: number;
  items: IItem[];
  created_at?: string;
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<MakeOrderPage />} />
      <Route path="orders" element={<ActiveOrdersPage />} />
      <Route path="admin" element={<AdminPage />} />
    </Routes>
  );
}

export default App;
