import { IOrder } from "../../App";
import { IItem } from "../../constants/coffees";
import optionsString from "../../helpers/optionsString";
import x from "../../images/x.png";

interface IProps {
  removeItemFromOrder: (item: IItem) => void;
  order: IOrder;
  totalAmount: number;
}

const OrderConfirmation = ({
  order,
  removeItemFromOrder,
  totalAmount,
}: IProps) => {
  return (
    <div className="h-full overflow-y-auto overflow-style-none scrollbar py-5 px-10 pb-16">
      <p className="font-bold text-lg text-lightblue">Zhrnutie objednávky:</p>
      <div className="mt-4">
        {order.items.map((item, idx) => (
          <div key={idx} className="flex justify-between py-0.5">
            <div className="flex items-center">
              <p className="font-bold text-xl">{`${item.count}x ${item.name}`}</p>
              {item.options && (
                <p className="pl-2 text-xl">{optionsString(item.options)}</p>
              )}
            </div>
            <div className="flex items-center gap-x-4 text-xl">
              <p>{`${(item.count! * item.price).toFixed(2)} €`}</p>
              <img
                onClick={() => removeItemFromOrder(item)}
                src={x}
                className="w-8 h-8"
              />
            </div>
          </div>
        ))}
        <hr className="border-lightblue mt-8 mb-3" />
        <div className="flex justify-end flex-col">
          <div className="flex items-end justify-end">
            <p className="font-bold text-lg">{`${totalAmount.toFixed(2)} €`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
