import { Link } from "react-router-dom";
import logo from "../../images/logoUPeCe_pastelBlue.png";

const Logo = ({ name }: { name: string }) => {
  return (
    <div className="flex relative w-full items-center pt-7">
      <div className="absolute left-5 flex">
        <img width={100} height={100} src={logo} alt="Logo" />
        <div className="flex items-center pl-8 gap-x-4 text-[#c6d0df] text-xl">
          <Link to="/">Domov</Link>
          <Link to="/orders">Objednávky</Link>
          <Link to="/admin">Správa</Link>
          {/* <Link to="/stats">Štatistiky</Link> */}
        </div>
      </div>
      <div className="w-full flex justify-center">
        <h1 className="text-[#c6d0df] flex text-6xl justify-center font-semibold">
          {name}
        </h1>
      </div>
    </div>
  );
};

export default Logo;
