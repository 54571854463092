import { IOption } from "./../constants/coffees";

const optionsString = (options: IOption[] | undefined) => {
  if (options && options.length > 0) {
    return `(${options
      .map((o: { short_name: string }) => o.short_name)
      .join(", ")})`;
  }
};

export default optionsString;
