const parseTime = (datetime: string) => {
  const time = new Date(datetime);

  const addZeroIfLess = (number: number) => {
    if (number < 10) return `0${number}`;
    else return number;
  };

  return `${addZeroIfLess(time.getHours())}:${addZeroIfLess(
    time.getMinutes()
  )}:${addZeroIfLess(time.getSeconds())}`;
};

export default parseTime;
